<template>
  <div class="remote-control m-4 mb-0 w-full overflow-y-auto">
    <vuescroll class="p-3">
      <div class="mx-4">
        <CurrentLocation :message="remote.message" />
        <Options :options="remote.options" />
      </div>
    </vuescroll>
  </div>
</template>

<script>
import vuescroll from 'vuescroll';
import CurrentLocation from './CurrentLocation.vue';
import Options from './Options.vue';

export default {
  name: 'RemoteControl',
  components: {
    vuescroll,
    CurrentLocation,
    Options
  },
  props: ['remote']
};
</script>

<style scoped>
.remote-control {
  max-width: 500px;
}
</style>
