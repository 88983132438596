<template>
  <div class="w-full bg-white rounded-2xl rounded-tl-none shadow-md">
    <div class="flex items-start justify-between px-6 py-4">
      <p class="self-center text-base text-lg font-bold mr-4">{{ message }}</p>
      <img class="w-10" src="../assets/img/remote-control.svg" alt="TOMI" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrentLocation',
  props: ['message']
};
</script>
