<template>
  <div class="flex justify-center items-center mt-4 w-full">
    <button
      class="w-full px-6 py-4 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 inline-flex items-center"
      :class="{
        'bg-tomi-yellow active:bg-yellow-600 text-black': button.category !== 2,
        'bg-white text-black': button.category === 2
      }"
      type="button"
      style="transition: all .15s ease"
      @click="clickButton"
    >
      <span class="font-icon text-xl" :class="[icon]"></span>
      <span class="ml-4">{{ button.label }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: ['button'],
  data() {
    return {
      icon: this.button.icon ? this.button.icon : 'icon-boat'
    };
  },
  methods: {
    clickButton: function() {
      this.$socket.emit('sendAction', {
        type: 'button',
        message: this.button.message ? this.button.message : null,
        wait: this.button.wait,
        action: this.button.action
      });
    }
  }
};
</script>
