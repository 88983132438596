<template>
  <div class="flex flex-col justify-center items-center mt-4 pb-4">
    <Input v-for="input in inputs" :key="input.action" :input="input" />
    <template v-for="button in buttons">
      <component :is="buttonComponent(button)" :key="button.action" :button="button"></component>
    </template>
  </div>
</template>

<script>
import Input from './Input.vue';
import Button from './Button.vue';
import ButtonLink from './ButtonLink.vue';

const buttonLinkActions = ['ShowDirections'];

export default {
  name: 'Options',
  props: ['options'],
  components: {
    Input,
    Button,
    ButtonLink
  },
  computed: {
    inputs: function() {
      return this.options.filter(b => b.type === 'input');
    },
    buttons: function() {
      return this.options.filter(b => b.type === 'button');
    }
  },
  methods: {
    buttonComponent(button) {
      return buttonLinkActions.includes(button.action) ? 'ButtonLink' : 'Button';
    }
  }
};
</script>
