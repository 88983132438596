import Vue from 'vue';
import App from './App.vue';

import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';
import vuescroll from 'vuescroll';

import '@/assets/css/tailwind.css';
import './registerServiceWorker';

const socketOptions = {
  query: {
    connectionType: 'tomicontrol'
  }
};

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketIO('https://socket.tomiworld.com', socketOptions),
    vuex: false
  })
);

Vue.use(vuescroll, {
  ops: {
    bar: {
      background: '#cecece'
    }
  },
  name: 'vueScroll'
});

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount('#app');
