<template>
  <div
    id="app"
    class="font-body flex portrait:flex-col landscape:flex-row w-screen view-height overflow-hidden"
  >
    <div class="w-full h-full portrait:px-3 landscape:px-6 bg-gradient-blue-gray">
      <div class="h-full flex flex-col items-center">
        <Header />
        <Disconnected v-if="!connected" />
        <Loading v-if="connected && loading" :message="message" />
        <RemoteControl v-if="connected && !loading" :remote="remote" />
        <GoHome v-if="showGoHome" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Disconnected from './components/Disconnected.vue';
import Loading from './components/Loading.vue';
import RemoteControl from './components/RemoteControl.vue';
import GoHome from './components/GoHome.vue';

export default {
  name: 'App',
  components: {
    Header,
    Disconnected,
    Loading,
    RemoteControl,
    GoHome
  },
  data: function() {
    return {
      connected: true,
      loading: true,
      message: 'Connecting to Server',
      remote: null
    };
  },
  mounted() {
    this.fixViewHeight();
    this.iOSPreventZoom();
  },
  sockets: {
    connect: function() {
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);
      const machineString = params.get('s');

      this.$socket.emit('identify', {
        machine: machineString
      });
    },
    remoteSessionWaitOnTomi: function() {
      this.waitOnTomi();
    },
    loadMenu: function(data) {
      this.message = 'Menu options received from TOMI';
      this.remote = data;
      this.loading = false;
    },
    disconnect: function() {
      this.connected = false;
    }
  },
  computed: {
    showGoHome: function() {
      if (this.remote) {
        const optionsWithActionHome = this.remote.options.find(b => b.action === 'Home');
        return this.connected && !this.loading && !(optionsWithActionHome === undefined);
      }

      return false;
    }
  },
  methods: {
    waitOnTomi: function() {
      this.message = 'Waiting for TOMI';
      this.loading = true;
    },
    fixViewHeight: function() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      // We listen to the resize event
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    },
    iOSPreventZoom: function() {
      document.addEventListener('gesturestart', function(e) {
        e.preventDefault();
      });
    }
  }
};
</script>
