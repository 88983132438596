<template>
  <div class="mt-auto px-4 w-full go-home">
    <div
      class="py-3 bg-black text-white flex items-center justify-between rounded-t-xl"
      @click="clickHomeButton"
    >
      <div class="ml-4 flex items-center">
        <img class="h-8" src="../assets/img/go-home.svg" alt="TOMI" />
        <span class="ml-4">Home</span>
      </div>
      <div class="mr-4 flex items-center">
        <img class="h-4" src="../assets/img/tomi-icons-white.svg" alt="TOMI" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clickHomeButton: function() {
      this.$socket.emit('sendAction', {
        type: 'button',
        message: '',
        wait: true,
        action: 'Home'
      });
    }
  }
};
</script>

<style scoped>
.go-home {
  max-width: 500px;
}
</style>
