<template>
  <div class="my-4 w-full">
    <div class="flex flex-col justify-center items-center">
      <div class="relative flex w-full flex-wrap items-stretch mb-2">
        <input
          v-bind:value="value"
          v-on:input="value = $event.target.value"
          :type="type"
          :placeholder="input.placeholder"
          class="px-6 py-4 placeholder-gray-500 text-black relative bg-gray-300 rounded-full text-sm border border-gray-400 outline-none focus:outline-none shadow w-full pr-10"
        />
        <span
          v-if="value"
          class="h-full flex justify-center items-center absolute w-10 right-0 pr-4 py-3"
        >
          <img v-if="valueIsValid" src="../assets/img/input-ok.svg" alt="ok" />
          <img v-else src="../assets/img/input-wrong.svg" alt="wrong" />
        </span>
      </div>
      <button
        class="w-full px-6 py-4 rounded-full outline-none focus:outline-none mr-1 mb-1 inline-flex items-center"
        :class="{
          'opacity-25 text-gray-300 bg-transparent border border-solid border-gray-300 cursor-not-allowed': !valueIsValid,
          'text-black bg-tomi-yellow active:bg-yellow-600 shadow hover:shadow-lg': valueIsValid
        }"
        :disabled="!valueIsValid"
        type="button"
        style="transition: all .15s ease"
        @click="clickButton"
      >
        <span class="font-icon text-xl" :class="[icon]"></span>
        <span class="ml-4">{{ input.label }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: ['input'],
  data: function() {
    return {
      value: '',
      icon: this.input.icon ? this.input.icon : 'icon-email'
    };
  },
  computed: {
    type: function() {
      let type = 'text';

      switch (this.input.category) {
        case 1:
          type = 'email';
          break;
      }

      return type;
    },
    valueIsValid: function() {
      let valid = false;

      switch (this.input.category) {
        case 1:
          valid = this.validateEmail(this.value);
          break;
        default:
          valid = this.value.length > 2;
      }

      return valid;
    }
  },
  methods: {
    clickButton: function() {
      if (this.valueIsValid) {
        this.$socket.emit('sendAction', {
          type: 'input',
          message: this.input.message ? this.input.message : null,
          wait: this.input.wait,
          action: this.input.action,
          value: this.value
        });
      }
    },
    validateEmail(email) {
      const regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;
      return regex.test(String(email).toLowerCase());
    }
  }
};
</script>
